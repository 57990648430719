import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    render(){
        return(
            <EditFile
                editPermission = {72}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {Strings.text.button.editTask}
                editContainerCSS = {''}
                url = {'Tasks/editTask.php'}
                buttonValue = {Strings.text.button.editTask}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.tid,
                        name: 'tid',
                        options: {
                                    required: true,
                                },
                    },{
                        type: 'text',
                        value: this.props.taskName,
                        name: 'name',
                        placeholder: Strings.text.placeholder.taskName,
                        options: {
                                    required: true,
                                 },
                        label: Strings.text.label.taskName,
                    },{
                        type: 'select',
                        value: this.props.fileId,
                        name: 'file_id',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black', 'Files/getFilesList.php'],
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                 },
                        label: Strings.text.label.selectFileForTask,
                    },{
                        type: 'select',
                        value: this.props.actionType,
                        name: 'action_type',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black', 'Industry/serviceIndustryList.php'],
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                 },
                        label: Strings.text.label.selectActionType,
                    },{
                        type: 'select',
                        value: this.props.did,
                        name: 'did',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black', 'Dictators/getDictatorsList.php'],
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                 },
                        label: Strings.text.label.selectDictator,
                    },{
                        type: 'text',
                        value: this.props.tat,
                        name: 'tat',
                        placeholder: Strings.text.placeholder.tat,
                        options: {
                                    required: true,
                                 },
                        label: Strings.text.label.tat,
                    }
                ]}
            />
        );
    }
}

export default Edit;


/*
    tid
    taskName
    fileId
    actionType
    did
    tat
   
*/