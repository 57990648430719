import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';

class Create extends React.Component{

    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    render(){
        return(
            <CreateFile
                createPermission = {71}
                createContainerCSS = {''}
                url = {'Tasks/addTask.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {Strings.text.button.addTaskButton}
                fields = {[{
                    type: 'select',
                    value: '',
                    name: 'file_id',
                    options: {
                                required: true,
                                showLoader: [true, 'black', 'Files/getUnassignedFilesList.php'],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                             },
                    label: Strings.text.label.selectFileForTask,
                },{
                    type: 'text',
                    value: '',
                    name: 'name',
                    placeholder: Strings.text.placeholder.taskName,
                    options: {
                                required: true,
                             },
                    label: Strings.text.label.taskName,
                },{
                    type: 'select',
                    value: '',
                    name: 'action_type',
                    options: {
                                required: true,
                                showLoader: [true, 'black', 'Industry/serviceIndustryList.php'],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                             },
                    label: Strings.text.label.selectActionType,
                },{
                    type: 'select',
                    value: '',
                    name: 'did',
                    id: 'dictatorList',
                    options: {
                                required: true,
                                showLoader: [true, 'black', 'Dictators/getDictatorsListWithTemplates.php'],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                             },
                    label: Strings.text.label.selectDictator,
                },{
                    type: 'select',
                    value: '',
                    name: 'template_id',
                    options: {
                                required: true,
                                showLoader: [true, 'black'],
                                onChange: 'dictatorList',
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                             },
                    label: Strings.text.label.selectTaskTemplate,
                },{
                    type: 'text',
                    value: '',
                    name: 'tat',
                    placeholder: Strings.text.placeholder.tat,
                    options: {
                                required: true,
                             },
                    label: Strings.text.label.tat,
                },]}
            />
        );
    }
}

export default Create;