import React from 'react';
import './App.css';
import 'draft-js/dist/Draft.css';
import 'draft-js-image-plugin/lib/plugin.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Login from 'pages/Login/Login';
import Signup from 'pages/Signup/Signup';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import ChangePassword from 'pages/ChangePassword/ChangePassword';
import WorkBench from 'pages/WorkBench/WorkBench';
import RedirectPage from 'pages/RedirectPage/RedirectPage';
import Transcription from 'pages/Transcription/Transcription';
import Indexing from 'pages/Indexing/Indexing';
import OCR from 'pages/OCR/OCR';
import Translation from 'pages/Translation/Translation';
import Dashboard from 'pages/Dashboard/Dashboard';
import Home from 'pages/Home/Home';

import NotificationBar from 'components/NotificationBar/NotificationBar';
import {connect} from 'react-redux';

class App extends React.Component {

  state = {
    dashboardTabs: this.props.dashboardTabs,
  };

  static dispatchError = (code) => {
      console.log(this.props);
      this.props.showError(code)
  }

  render(){  
        return (
            <NotificationBar>
              <BrowserRouter >
                <Switch>
                  <Route path='/login' component={Login} />
                  <Route path='/signup' component={Signup} />
                  <Route path='/forget-password' component={ForgetPassword} />
                  <Route path='/change-password' component={ChangePassword} />
                  <Route path='/redirect/:id/:perm/:tab/:redir' component={RedirectPage} />
                  <Route path='/dashboard' render={(props) => (
                    !localStorage.getItem("awx") ? (<Redirect to="/login"/>) : (<Dashboard {...props}>
                      <Switch >
                        <Route path = {"/dashboard/workbench"} component={WorkBench} />
                        <Route path='/dashboard/home' render={() => (
                          <Home />
                        )} />
                      </Switch>
                    </Dashboard>)
                  )} />
                  <Route path='/' component={Login} />
                </Switch>
              </BrowserRouter>
            </NotificationBar>
        );
      }
}

const mapStateToProps = state => {
	return {
      dashboardTabs: state.tabsReducer.dashboardTabs,
  }
}

export default connect(mapStateToProps, null)(App);
