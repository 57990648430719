import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    render(){
        return(
            <EditFile
                editPermission = {18}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {Strings.text.button.editDictator}
                editContainerCSS = {''}
                url = {'Dictators/editDictator.php'}
                buttonValue = {Strings.text.button.editDictator}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                groups = {[{id:1}]}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.did,
                        name: 'did',
                        groupid: 1,
                        options: {
                                    required: true,
                                },
                    },{
                        type: 'alphabets',
                        value: this.props.name,
                        name: 'name',
                        placeholder: Strings.text.placeholder.name,
                        groupid: 1,
                        options: {
                                    required: true,
                                    minLength: 5,
                                    },
                        label: Strings.text.label.name,
                    },{
                        type: 'text',
                        value: this.props.nickname,
                        name:'nickname',
                        placeholder: Strings.text.placeholder.nickname,
                        groupid: 1,
                        options: {
                                    required: true,
                                    },
                        label: Strings.text.label.nickname,
                    },{
                        type: 'email',
                        value: this.props.email,
                        name: 'email',
                        placeholder: Strings.text.placeholder.email,
                        groupid: 1,
                        options: {
                                    required: false,
                                },
                        label: Strings.text.label.email,
                    },{
                        type: 'text',
                        value: this.props.address,
                        name:'address',
                        placeholder: Strings.text.placeholder.address,
                        groupid: 1,
                        options: {
                                    required: false,
                                    },
                        label: Strings.text.label.address,
                    },{
                        type: 'number',
                        value: this.props.phone,
                        name:'phone',
                        placeholder: Strings.text.placeholder.phone,
                        groupid: 1,
                        options: {
                                    required: false,
                                    minLength: 10,
                                    maxLength: 10,
                                    },
                        label: Strings.text.label.phone,
                    },{
                        type: 'radio',
                        value: this.props.gender.toLowerCase(),
                        name:'gender',
                        groupid: 1,
                        options: {
                                    required: true,
                                    group : 'gender',
                                    options:[
                                        {value: Strings.text.value.male, name: 'male', selected: this.props.gender.toLowerCase()=='male'?true:false},
                                        {value: Strings.text.value.female, name: 'female', selected: this.props.gender.toLowerCase()=='female'?true:false},
                                    ],
                                },
                        label: Strings.text.label.gender,
                    },{
                        type: 'radio',
                        value: this.props.directPost,
                        name:'directPost',
                        groupid: 1,
                        options: {
                                    required: true,
                                    group : 'directPost',
                                    options:[
                                        {value: Strings.text.value.directPostYes, name: '1', selected: this.props.directPost=='1'?true:false},
                                        {value: Strings.text.value.directPostNo, name: '0', selected: this.props.directPost=='0'?true:false},
                                    ],
                                },
                        label: Strings.text.label.directPost,
                    },{
                        type: 'radio',
                        value: this.props.asr,
                        name:'asr',
                        groupid: 1,
                        options: {
                                    required: true,
                                    group : 'asr',
                                    options:[
                                        {value: Strings.text.value.asryes, name: '1', selected: this.props.asr=='1'?true:false},
                                        {value: Strings.text.value.asrno, name: '0', selected: this.props.asr=='0'?true:false},
                                    ],
                                },
                        label: Strings.text.label.asr,
                    },{
                        type: 'number',
                        value: this.props.tat,
                        name:'tat',
                        groupid: 1,
                        options: {
                                    required: true,
                                },
                        label: Strings.text.label.tatinhours,
                    },{
                        type: 'select',
                        value: this.props.industry_id,
                        name: 'indid',
                        id: 'industryList',
                        groupid: 1,
                        options: {
                                    required: true,
                                    showLoader: [true, 'black', 'Industry/getIndustryWithInstAndSpec.php'],
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                },
                        label: Strings.text.label.selectIndustry,
                    },{
                        type: 'select',
                        value: this.props.iid,
                        name: 'iid',
                        id: 'instituteList',
                        groupid: 1,
                        options: {
                                    required: true,
                                    showLoader: [true, 'black'],
                                    onChange: 'industryList',
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                },
                        label: Strings.text.label.selectInstitute,
                    },{
                        type: 'select',
                        value: this.props.specialization_id,
                        name: 'sid',
                        groupid: 1,
                        options: {
                                    required: true,
                                    showLoader: [true, 'black'],
                                    onChange: 'instituteList',
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                },
                        label: Strings.text.label.selectSpecialization,
                    }
                ]}
            />
        );
    }
}

export default Edit;


/*
    did
    specialization
    institute
    gender
    phone
    address
    email
    name
*/