import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    render(){
        return(
            <EditFile
                editPermission = {79}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {Strings.text.button.editFileLocationInfo}
                editContainerCSS = {''}
                url = {'Files/editFilesLocation.php'}
                buttonValue = {Strings.text.button.editFileLocationInfo}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                groups = {[{id:1}]}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.did,
                        name: 'did',
                        groupid: 1,
                        options: {
                                    required: true,
                                },
                    },{
                        type: 'text',
                        value: this.props.ipaddress,
                        name:'ipaddress',
                        placeholder: Strings.text.placeholder.ipaddress,
                        groupid: 1,
                        options: {
                                    required: true,
                                    },
                        label: Strings.text.label.ipaddress,
                    },{
                        type: 'text',
                        value: this.props.username,
                        name:   'username',
                        placeholder: Strings.text.placeholder.username,
                        groupid: 1,
                        options: {
                                    required: true,
                                    },
                        label: Strings.text.label.username,
                    },{
                        type: 'text',
                        value: this.props.password,
                        name:   'hostpassword',
                        placeholder: Strings.text.placeholder.hostpassword,
                        groupid: 1,
                        options: {
                                    required: true,
                                    },
                        label: Strings.text.label.hostpassword,
                    },{
                        type: 'text',
                        value: this.props.audiodirectory,
                        name:   'audiodirectory',
                        placeholder: Strings.text.placeholder.audioPath,
                        groupid: 1,
                        options: {
                                    required: true,
                                    },
                        label: Strings.text.label.audioPath,
                    },{
                        type: 'text',
                        value: this.props.filesdirectory,
                        name:   'filesdirectory',
                        placeholder: Strings.text.placeholder.filesPath,
                        groupid: 1,
                        options: {
                                    required: true,
                                    },
                        label: Strings.text.label.filesPath,
                    }
                ]}
            />
        );
    }
}

export default Edit;


/*
    did
*/