import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    render(){
        return(
            <EditFile
                editPermission = {48}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {Strings.text.button.editRoles}
                editContainerCSS = {''}
                url = {'Users/editUserRole.php'}
                buttonValue = {Strings.text.button.updateRoles}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.uid,
                        name: 'uid',
                        options: {
                                    required: true,
                                },
                    },{
                        type: 'checkbox',
                        value: this.props.siid,
                        name: 'siid',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black', 'Industry/getIndustryWithServices.php?uid='+this.props.uid],
                                    group: 'industryList',
                                    newLine: true,
                                    options: [
                                        {value: '', name: '', selected: false},
                                    ]
                                },
                        label: Strings.text.label.selectIndustry,
                    }
                ]}
            />
        );
    }
}

export default Edit;


/*
    uid, industry
*/