import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    render(){
        return(
            <EditFile
                editPermission = {8}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {Strings.text.button.assignVendor}
                editContainerCSS = {''}
                url = {'Users/assignVendor.php'}
                buttonValue = {Strings.text.button.assignVendor}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.uid,
                        name: 'uid',
                        options: {
                                    required: true,
                                },
                    },{
                        type: 'select',
                        value: this.props.vendor,
                        name: 'vid',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black', 'Vendors/getVendorList.php'],
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                 },
                        label: Strings.text.label.selectVendor,
                    }
                ]}
            />
        );
    }
}

export default Edit;


/*
    uid, vendor
*/