import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';

class Create extends React.Component{

    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    render(){
        return(
            <React.Fragment>
                <h4>{Strings.text.heading.AddFile}</h4>
                <CreateFile
                    createPermission = {57}
                    createContainerCSS = {''}
                    url = {'Files/createFile.php'}
                    onSuccess = {(data) => this.onSuccess(data)}
                    onFailure = {(data) => this.onFailure(data)}
                    buttonValue = {Strings.text.button.createFile}
                    fields = {[{
                            type: 'text',
                            value: '',
                            name: 'name',
                            placeholder: Strings.text.placeholder.fileName,
                            options: {
                                        required: true,
                                    },
                            label: Strings.text.label.fileName,
                        },{
                            type: 'select',
                            value: '',
                            name: 'file_type',
                            options: {
                                        required: true,
                                        showLoader: [true, 'black', 'Files/fileTypeList.php'],
                                        options: [
                                            {value: '', name: '', selected: true},
                                        ]
                                    },
                            label: Strings.text.label.selectFileType,
                        }]}
                />
            </React.Fragment>    
        );
    }
}

export default Create;