import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';

class Create extends React.Component{

    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    render(){
        return(
            <React.Fragment>
            <h4>{Strings.text.heading.AddDictator}</h4>
            <CreateFile
                createPermission = {17}
                createContainerCSS = {''}
                url = {'Dictators/addDictator.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {Strings.text.button.addDictator}
                groups = {[{id:1}]}
                fields = {[{
                    type: 'alphabets',
                    value: '',
                    name: 'name',
                    placeholder: Strings.text.placeholder.name,
                    groupid: 1,
                    options: {
                                required: true,
                                minLength: 5,
                                },
                    label: Strings.text.label.name,
                },{
                    type: 'text',
                    value: '',
                    name:'nickname',
                    placeholder: Strings.text.placeholder.nickname,
                    groupid: 1,
                    options: {
                                required: true,
                                },
                    label: Strings.text.label.nickname,
                },{
                    type: 'email',
                    value: '',
                    name: 'email',
                    placeholder: Strings.text.placeholder.email,
                    groupid: 1,
                    options: {
                                required: false,
                            },
                    label: Strings.text.label.email,
                },{
                    type: 'password',
                    value: '',
                    name: 'password',
                    placeholder: Strings.text.placeholder.pass,
                    groupid: 1,
                    options: {
                                required: false,
                            },
                    label: Strings.text.label.pass,
                },{
                    type: 'text',
                    value: '',
                    name:'address',
                    placeholder: Strings.text.placeholder.address,
                    groupid: 1,
                    options: {
                                required: false,
                                },
                    label: Strings.text.label.address,
                },{
                    type: 'number',
                    value: '',
                    name:'phone',
                    placeholder: Strings.text.placeholder.phone,
                    groupid: 1,
                    options: {
                                required: false,
                                minLength: 10,
                                maxLength: 10,
                                },
                    label: Strings.text.label.phone,
                },{
                    type: 'radio',
                    value: 'male',
                    name:'gender',
                    groupid: 1,
                    options: {
                                required: true,
                                group : 'gender',
                                options:[
                                    {value: Strings.text.value.male, name: 'male', selected: true},
                                    {value: Strings.text.value.female, name: 'female', selected: false},
                                ],
                            },
                    label: Strings.text.label.gender,
                },{
                    type: 'radio',
                    value: '0',
                    name:'directPost',
                    groupid: 1,
                    options: {
                                required: true,
                                group : 'directPost',
                                options:[
                                    {value: Strings.text.value.directPostYes, name: '1', selected: false},
                                    {value: Strings.text.value.directPostNo, name: '0', selected: true},
                                ],
                            },
                    label: Strings.text.label.directPost,
                },{
                    type: 'radio',
                    value: '1',
                    name:'asr',
                    groupid: 1,
                    options: {
                                required: true,
                                group : 'asr',
                                options:[
                                    {value: Strings.text.value.asryes, name: '1', selected: true},
                                    {value: Strings.text.value.asrno, name: '0', selected: false},
                                ],
                            },
                    label: Strings.text.label.asr,
                },{
                    type: 'number',
                    value: '24',
                    name:'tat',
                    groupid: 1,
                    options: {
                                required: true,
                            },
                    label: Strings.text.label.tatinhours,
                },{
                    type: 'select',
                    value: '',
                    name: 'indid',
                    id: 'industryList',
                    groupid: 1,
                    options: {
                                required: true,
                                showLoader: [true, 'black', 'Industry/getIndustryWithInstAndSpec.php'],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.selectIndustry,
                },{
                    type: 'select',
                    value: '',
                    name: 'iid',
                    id: 'instituteList',
                    groupid: 1,
                    options: {
                                required: true,
                                showLoader: [true, 'black'],
                                onChange: 'industryList',
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.selectInstitute,
                },{
                    type: 'select',
                    value: '',
                    name: 'sid',
                    groupid: 1,
                    options: {
                                required: true,
                                showLoader: [true, 'black'],
                                onChange: 'instituteList',
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.selectSpecialization,
                }]}
            />
            </React.Fragment>
        );
    }
}

export default Create;